import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RequiredAsterisk from "../ui/icons/RequiredAsterisk";
import { QUERY_PARAMS_KEY } from "../../constants/keys";
import { ERR_MSGS } from "../../constants/errorMsgs";
import { regexPatterns } from "../../constants/regexPatterns";
import { reqToResetPassword } from "../../services/auth.service";
import { toast } from "react-toastify";

const ForgotPassword: React.FC = (props: any) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get(QUERY_PARAMS_KEY.REDIRECT_URI) || "";
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID) || "";

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setEmail(value);
  };

  const handleFocus = () => {
    setEmailError("");
  };

  const handleBlur = () => {
    if (!regexPatterns.email.test(email)) setEmailError(ERR_MSGS.Invalid_Email);
    else setEmailError("");
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const reqParams = {
      email: email,
      clientID: clientID,
      redirectUri: redirectURI,
    };

    const res = await reqToResetPassword(reqParams);
    if (!res?.mailSent) return;
    navigate("/email-sent", { state: { email: email } });

    // Perform password reset logic here
  };

  const handleNavToSignIn = () => {
    return `/sign-in?${clientID ? "client_id=" + clientID : ""}${
      redirectURI ? "&redirect_URI=" + redirectURI : ""
    }`;
  };

  useEffect(() => {
    if (emailError || !email) setIsDisabled(true);
    else setIsDisabled(false);
  }, [email, emailError]);

  return (
    <>
      <div className="flex justify-center items-center h-screen p-5">
        <div>
          <img className="mb-9" src="./images/web-logo.png" alt="web-logo" />

          <div className="bg-sky border border-sky py-5 md:py-9 px-4 md:px-16 rounded-[20px] max-w-[448px]">
            <h3 className="text-secondary text-2xl font-semibold mb-2">
              Reset your password
            </h3>
            <p className="text-sm font-light text-secondary text-opacity-80 mb-4">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </p>
            <form onSubmit={onSubmit}>
              <div className="mb-6">
                <label className="text-base text-secondaryVariant">
                  Email
                  <RequiredAsterisk />
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white mt-2"
                  value={email}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {emailError && (
                  <p className="text-red-600 pl-5">{emailError}</p>
                )}
              </div>
              <button
                className={`btnPrimary ${isDisabled ? "opacity-50" : ""}`}
                type="submit"
                disabled={isDisabled}
              >
                Reset Password
              </button>{" "}
            </form>
            <p className="flex items-center justify-center gap-3 text-base text-secondaryVariant text-opacity-50 mt-6">
              Return to{" "}
              <Link
                to={handleNavToSignIn()}
                className=" text-secondary font-medium"
              >
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

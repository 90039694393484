import FacebookLogin from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fbAppId } from "../../constants/environment.constants";
import { ERR_MSGS } from "../../constants/errorMsgs";
import {
  initCreateAccountErrorForm,
  initCreateAccountForm,
} from "../../constants/initialForms";
import { QUERY_PARAMS_KEY, STORAGE_KEYS } from "../../constants/keys";
import { regexPatterns } from "../../constants/regexPatterns";
import { signUp } from "../../services/auth.service";
import { IRegistrationFormErrors } from "../../types/IRegistrationForm";
import RequiredAsterisk from "../ui/icons/RequiredAsterisk";
import { jwtDecode } from "jwt-decode";

const CreateAccountForm = () => {
  const [createAccForm, setCreateAccForm] = useState<IRegistrationFormErrors>(
    initCreateAccountForm
  );
  const [createAccFormErrors, setCreateAccFormErrors] = useState(
    initCreateAccountErrorForm
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get(QUERY_PARAMS_KEY.REDIRECT_URI);
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);
  const role = urlParams.get(QUERY_PARAMS_KEY.ROLE) || location?.state?.role;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    setCreateAccForm((prev) => ({ ...prev, [name]: value }));
    setCreateAccFormErrors((prev) => ({ ...prev, createAccountError: false }));
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    if (name === "confirmPassword") {
      setCreateAccFormErrors((prev) => ({
        ...prev,
        confirmPasswordError: "",
        createAccountError: false,
      }));
      return;
    }

    const fieldMappings: { [key: string]: string } = {
      email: "emailError",
      password: "passwordError",
      // confirmPassword: "confirmPasswordError",
    };
    const errorKey = fieldMappings[name];
    if (errorKey) {
      setCreateAccFormErrors((prev) => ({
        ...prev,
        [errorKey]: "",
        createAccountError: false,
      }));
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    const fieldMappings: { [key: string]: { key: string; errorMsg: string } } =
      {
        email: {
          key: "emailError",
          errorMsg: ERR_MSGS?.Invalid_Email,
        },
        password: {
          key: "passwordError",
          errorMsg: ERR_MSGS.Invalid_Password_Pattern,
        },
        // confirmPassword: {
        //   key: "confirmPasswordError",
        //   errorMsg: ERR_MSGS?.Name_length,
        // },
      };
    if (
      name === "confirmPassword" &&
      createAccForm?.confirmPassword !== createAccForm?.password
    ) {
      setCreateAccFormErrors((prev) => ({
        ...prev,
        confirmPasswordError: ERR_MSGS.Password_Does_Not_Match,
      }));
    }

    const fieldMapping = fieldMappings[name];
    if (fieldMapping) {
      const { key, errorMsg } = fieldMapping;
      const { value } = event?.target;
      const regexPatternFind: { [key: string]: RegExp } = {
        email: regexPatterns?.email,
        password: regexPatterns?.password,
      };

      if (name in regexPatternFind && !regexPatternFind[name].test(value)) {
        setCreateAccFormErrors((prev) => ({ ...prev, [key]: errorMsg }));
      }
    }
  };

  const handleSubmit = async () => {
    const reqBody = {
      email: createAccForm?.email,
      password: createAccForm?.password,
      role: role,
    };
    const res = await signUp(
      redirectURI as string,
      clientID as string,
      reqBody
      // rememberMe
    );
    if (res?.ok) {
      // window.location.replace(
      //   `${redirectURI}${"?authCode=" + res?.data?.code}`
      //   // `${redirectURI}${"?authCode=" + res?.data?.code}&${"remember_me=" + rememberMe
      //   // }`
      // );

      localStorage.setItem(STORAGE_KEYS.ROLE, role);
      const decodedToken: any = jwtDecode(res?.data?.access_token);
      navigate(
        `/verification-email?${clientID ? "client_id=" + clientID : ""}${
          redirectURI && "&redirect_URI=" + redirectURI
        }`,
        { state: decodedToken?.email }
      );
    } else
      setCreateAccFormErrors((prev) => ({ ...prev, createAccountError: true }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const noError = Object.values(createAccFormErrors).every(
      (value) => value === "" || value === false
    );
    if (!noError) return;
    else handleSubmit();
  };

  const handleCancel = () => {
    navigate(
      `/register-option${clientID ? "?client_id=" + clientID : ""}${
        redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`
    );
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      onSocialSignUp("google", credentialResponse?.access_token as string);
      console.log("credentialResponse", credentialResponse);
    },
    onError: () => {
      toast.error("Couldn't Sign In with Google. Try again later");
      console.log("Login Failed");
    },
    flow: "implicit",
  });

  const onSocialSignUp = async (provider: string, providerToken: string) => {
    const reqBody = {
      provider,
      providerToken,
      role,
    };
    const res = await signUp(
      redirectURI as string,
      clientID as string,
      reqBody
      // rememberMe
    );
    if (res?.ok) {
      const decodedToken: any = jwtDecode(res?.data?.access_token);
      navigate(
        `/verification-email?${clientID ? "client_id=" + clientID : ""}${
          redirectURI && "&redirect_URI=" + redirectURI
        }`,
        { state: decodedToken?.email }
      );
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleNavToSignIn = () => {
    return `/sign-in${clientID ? "?client_id=" + clientID : ""}${
      redirectURI ? "&redirect_URI=" + redirectURI : ""
    }`;
  };

  useEffect(() => {
    const isFieldEmpty = Object.values(createAccForm).some(
      (value) => value === "" || value === false
    );

    const noError = Object.values(createAccFormErrors).every(
      (value) => value === false || value === ""
    );
    if (!isFieldEmpty && noError) setIsDisabled(false);
    else setIsDisabled(true);
  }, [createAccForm, createAccFormErrors]);
  return (
    <>
      <div className="flex justify-center items-center flex-col px-3 py-5">
        <div>
          <img className="mb-9" src="./images/web-logo.png" alt="web-logo" />

          <div className="bg-sky border border-sky py-5 md:py-9 px-4 md:px-16 rounded-[20px] max-w-[448px]">
            <h3 className="text-secondary text-xl md:text-2xl font-semibold mb-4">
              Create your account{" "}
            </h3>
            <form onSubmit={onSubmit}>
              <div className="mb-4 md:mb-6">
                <label className="text-base text-secondaryVariant">
                  Email
                  <RequiredAsterisk />
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white mt-2"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                {createAccFormErrors?.emailError && (
                  <p className="text-red-600 pl-5">
                    {createAccFormErrors?.emailError}
                  </p>
                )}
              </div>
              <div className="mb-4 md:mb-6">
                <label className="text-base text-secondaryVariant mb-2">
                  Create Your Password
                  <RequiredAsterisk />
                </label>

                <div className="relative">
                  <input
                    type={showPassword ? "text" : "Password"}
                    name="password"
                    placeholder="Enter Your Password"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  <button
                    className="absolute right-2 top-[17px]"
                    type="button"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? (
                      <LuEyeOff className="w-4 h-5 text-secondary" />
                    ) : (
                      <LuEye className="w-4 h-5 text-secondary" />
                    )}
                  </button>
                </div>
                {createAccFormErrors?.passwordError && (
                  <p className="text-red-600 pl-5">
                    {createAccFormErrors?.passwordError}
                  </p>
                )}
              </div>
              <div className="mb-4 md:mb-6">
                <label className="text-base text-secondaryVariant mb-2">
                  Confirm Password
                  <RequiredAsterisk />
                </label>

                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "Password"}
                    name="confirmPassword"
                    placeholder="Re-enter Your Password"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant w-full bg-white"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  <button
                    className="absolute right-2 top-[17px]"
                    type="button"
                    onClick={handleShowConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <LuEyeOff className="w-4 h-5 text-secondary" />
                    ) : (
                      <LuEye className="w-4 h-5 text-secondary" />
                    )}
                  </button>
                </div>
                {createAccFormErrors?.confirmPasswordError && (
                  <p className="text-red-600 pl-5">
                    {createAccFormErrors?.confirmPasswordError}
                  </p>
                )}
              </div>
              {createAccFormErrors?.createAccountError && (
                <p className="text-red-600 pl-5">
                  {createAccFormErrors?.createAccountError}
                </p>
              )}
              <div className="mb-7">
                <h3 className="text-base font-medium text-secondary mb-4">
                  Your password must contain:
                </h3>
                <ul className="flex items-start flex-col gap-2">
                  <li className="text-base text-secondaryVariant list-disc ms-4">
                    At least 12 characters long but 14 or more is better.
                  </li>
                  <li className="text-base text-secondaryVariant list-disc ms-4">
                    A combination of uppercase letters, lowercase letters,
                    numbers, and symbols.{" "}
                  </li>
                </ul>
              </div>
              <button
                className={`btnPrimary ${isDisabled ? "opacity-50" : ""}`}
                type="submit"
                disabled={isDisabled}
              >
                Continue
              </button>
              <p className="flex items-center justify-center gap-3 text-base text-secondaryVariant text-opacity-50 mt-6">
                Already have an account?{" "}
                <Link
                  to={handleNavToSignIn()}
                  className={`text-secondary font-medium`}
                >
                  Sign In
                </Link>
              </p>
            </form>
          </div>
          <div className="flex items-center px-5 md:px-16 gap-3 flex-col">
            <p className="text-sm text-secondary text-opacity-50 mt-3">OR</p>
            <button
              className="flex items-center gap-3 text-base text-secondary border border-secondaryVariant rounded-lg w-full py-2.5 px-3 hover:shadow-btnShadow"
              onClick={() => googleLogin()}
            >
              <FcGoogle className="w-[26px] h-[26px]" />
              Continue with Google
            </button>
            <FacebookLogin
              appId={fbAppId}
              onSuccess={(response) => {
                onSocialSignUp("facebook", response?.accessToken);
              }}
              onFail={(err) => {
                if (err?.status === "loginCancelled") {
                  return;
                }
                toast.error("Couldn't Sign Up with Facebook. Try again later");
              }}
              render={(props) => (
                <button
                  className="flex items-center gap-3 text-base text-secondary border border-secondaryVariant rounded-lg w-full py-2.5 px-3 hover:shadow-btnShadow"
                  type="button"
                  onClick={props.onClick}
                >
                  <BiLogoFacebookCircle className="w-[26px] h-[26px] text-[#0866ff]" />
                  Continue with Facebook
                </button>
              )}
            />
            {/* <button className="flex items-center gap-3 text-base text-secondary border border-secondaryVariant rounded-lg w-full py-2.5 px-3 hover:shadow-btnShadow">
              <FaApple className="w-[26px] h-[26px] text-black" />
              Continue with Apple
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccountForm;

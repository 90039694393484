export const STORAGE_KEYS = {
  ACC_TOKEN: "accToken",
  RF_TOKEN: "rfToken",
  USER: "user",
  REMEMBER_ME: "rememberMe",
  ROLE: "role",
  EDIT: "isEditing"
};

export const QUERY_PARAMS_KEY = {
  REDIRECT_URI: "redirect_URI",
  CLIENT_ID: "client_id",
  ROLE: "role",
  TOKEN: "token",
  EDIT: "edit"
}
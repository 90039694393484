import React, { useState } from "react";
import { USER_ROLES } from "../../constants/roles";
import RegisterOptionTabs from "../Tabs/RegisterOptionTabs";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS } from "../../constants/keys";

const options = {};
interface Props {
  redirectURI: string;
  clientID: string;
  role: string;
}
const RegistrationOption: React.FC<Props> = ({
  redirectURI,
  clientID,
  role,
}) => {
  const [optionSelect, setOptionSelect] = useState("register");
  const [proceedingRole, setProceedingRole] = useState(
    role || USER_ROLES?.PARENT
  );
  const navigate = useNavigate();

  const handleRegisterOptionSelect = (role: string) => {
    setProceedingRole(role);
  };

  const handleOptionSelect = (option: string) => {
    setOptionSelect(option);
  };

  const handleNavToSignIn = () => {
    navigate(
      `/sign-in${clientID ? "?client_id=" + clientID : ""}${
        redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`,
      { state: { role: proceedingRole } }
    );
  };
  const handleRegisterNow = () => {
    navigate(
      `/create-account${clientID ? "?client_id=" + clientID : ""}${
        redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`,
      { state: { role: proceedingRole } }
    );
  };
  return (
    <>
      <div>
        <RegisterOptionTabs
          optionSelect={optionSelect}
          handleOptionSelect={handleOptionSelect}
        />
        <div className="w-full md:max-w-[345px] flex flex-col gap-5 md:gap-9">
          <h3 className="text-xl md:text-2xl font-bold text-secondary text-start">
            Register As
          </h3>
          <div className="flex flex-col items-center gap-4 ">
            <div
              className={`border ${
                proceedingRole === USER_ROLES?.CENTER
                  ? "border-tertiary"
                  : "border-secondaryVariant"
              } hover:border-tertiary rounded-[10px] py-3 px-4 flex items-center justify-left  gap-3 cursor-pointer w-full`}
              onClick={() => {
                handleRegisterOptionSelect(USER_ROLES?.CENTER);
              }}
            >
              <input
                name="center"
                type="radio"
                className="accent-tertiary w-4 h-4 cursor-pointer bg-white"
                checked={proceedingRole === USER_ROLES?.CENTER}
                readOnly
              />
              <label
                htmlFor=""
                className="text-base text-secondary cursor-pointer"
              >
                I’m a center
              </label>
            </div>
            <div
              className={`border ${
                proceedingRole === USER_ROLES?.PARENT
                  ? "border-tertiary"
                  : "border-secondaryVariant"
              } hover:border-tertiary rounded-[10px] py-3 px-4 w-full flex items-center justify-left gap-3 cursor-pointer`}
              onClick={() => {
                handleRegisterOptionSelect(USER_ROLES?.PARENT);
              }}
            >
              <input
                name="parent"
                type="radio"
                className="accent-tertiary w-4 h-4 cursor-pointer bg-white"
                checked={proceedingRole === USER_ROLES?.PARENT}
                readOnly
              />
              <label
                htmlFor=""
                className="text-base text-secondary cursor-pointer"
              >
                I’m a parent
              </label>
            </div>

            <div
              className={`border ${
                proceedingRole === USER_ROLES?.TEACHER
                  ? "border-tertiary"
                  : "border-secondaryVariant"
              } hover:border-tertiary rounded-[10px] py-3 px-4 w-full flex items-center justify-left gap-3 cursor-pointer`}
              onClick={() => {
                handleRegisterOptionSelect(USER_ROLES?.TEACHER);
              }}
            >
              <input
                name="teacher"
                type="radio"
                className="accent-tertiary w-4 h-4 cursor-pointer bg-white"
                checked={proceedingRole === USER_ROLES?.TEACHER}
                readOnly
              />
              <label
                htmlFor=""
                className="text-base text-secondary cursor-pointer"
              >
                I’m a Teacher
              </label>
            </div>
          </div>

          <button className="btnPrimary" onClick={handleRegisterNow}>
            Register now
          </button>
          <p className="text-base text-secondaryVariant flex items-center justify-center gap-2">
            Already have an account?
            <button
              className="text-secondary font-medium"
              onClick={handleNavToSignIn}
            >
              Sign In{" "}
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default RegistrationOption;

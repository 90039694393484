import React from "react";

interface Props {
  optionSelect: string;
  handleOptionSelect: (option: string) => void;
}

const RegisterOptionTabs: React.FC<Props> = ({
  optionSelect,
  handleOptionSelect,
}) => {
  return (
    <>
      <div className="mb-5 md:mb-10">
        <nav className="flex justify-normal items-center gap-2.5 w-full">
          {/* <button className={`${optionSelect === "login" ? "text-secondary border-secondary" : "text-secondaryLight border-secondaryLight"} text-base hover:text-secondary border-b  hover:border-secondary text-center w-1/2 pb-4 cursor-pointer`} onClick={() => { handleOptionSelect('login') }}>
            Login
          </button> */}
          <button
            className="text-primary border-primary text-base border-b text-center  py-4 bg-white w-full" //w-[168px]
            onClick={() => {
              handleOptionSelect("register");
            }}
            disabled={true}
          >
            Register
          </button>
        </nav>
      </div>
    </>
  );
};

export default RegisterOptionTabs;

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAMS_KEY, STORAGE_KEYS } from "../../constants/keys";
import { signIn, signOut } from "../../services/auth.service";
import { RootState } from "../../store/store";
// import { clientID } from "../../constants/environment.constants";

const LoaderPage = () => {
  //   const [user, setUser] = useState<IUser>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get(QUERY_PARAMS_KEY.REDIRECT_URI);
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);
  const rfToken = localStorage.getItem(STORAGE_KEYS.RF_TOKEN);
  const rememberMeLocal = localStorage.getItem(STORAGE_KEYS.REMEMBER_ME);
  const { User } = useSelector((state: RootState) => state.auth);

  const handleContinue = async () => {
    const res = await signIn(
      redirectURI as string,
      clientID as string,
      undefined,
      undefined,
      rfToken as string
    );
    if (res?.ok) {
      window.location.replace(
        `${redirectURI}${"?authCode=" + res?.data?.authCode}&${"remember_me=" + rememberMeLocal
        }`
      );
    }
  };

  const handleUseAnotherAcc = () => {
    signOut();
    navigate(
      `/sign-in${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`
    );
  };

  return (
    <>
      <div className="flex justify-center items-center h-screen p-5">
        <div className="bg-sky border border-sky py-5 md:py-9 px-4 md:px-16 rounded-[20px] w-full max-w-[800px]">
          <h3 className="text-lg font-medium text-secondary mb-4">
            Do you want to continue with the same account?
          </h3>
          <div className="flex flex-col sm:flex-row items-center justify-around border border-secondaryVariant rounded gap-4 p-4 md:p-8">
            <button
              className="btnSimple sm:max-w-[250px] truncate"
              onClick={handleContinue}
              disabled={!User?.email}
            >
              {User?.email || "No account available"}
            </button>

            <button
              onClick={handleUseAnotherAcc}
              className=" text-blue-500 rounded-lg border border-secondaryVariant p-3 w-full"
            >
              Use/Register another account.
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoaderPage;

import React from 'react'
import CompletingRegistration from '../../components/completingRegistration/CompletingRegistration'

const CompleteRegistration = () => {
  return (
      <>
      <CompletingRegistration/>
      </>
)
}

export default CompleteRegistration
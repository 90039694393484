import React from "react";

const UnlockExclusiveBenefits = () => {
  return (
    <>
      <div className="bg-white rounded-xl py-5 md:py-12 px-5 md:px-9 flex flex-col items-start gap-4 md:gap-6">
        <h2 className="text-xl lg:text-[28px] text-secondary font-bold max-w-[345px]">
          Unlock Exclusive Benefits for Your Child!
        </h2>
        <h3 className="text-base md:text-lg text-secondary font-semibold">
          💺 Secure Spots Quickly:{" "}
          <span className="font-normal text-secondaryLight">
            {" "}
            Ensure your child gets a place in the best programs fast!
          </span>
        </h3>
        <h3 className="text-base md:text-lg text-secondary font-semibold">
          👀 Find the Perfect Fit:{" "}
          <span className="font-normal text-secondaryLight">
            {" "}
            Discover standout childcare options tailored to your needs!
          </span>
        </h3>
        <h3 className="text-base md:text-lg text-secondary font-semibold">
          {" "}
          🎉 Access Exclusive Offers:{" "}
          <span className="font-normal text-secondaryLight">
            {" "}
            Take advantage of special deals designed just for your family!
          </span>
        </h3>
      </div>
    </>
  );
};

export default UnlockExclusiveBenefits;

import React from "react";

const EmailSent: React.FC = (props: any) => {
  return (
    <>
      <div className="flex justify-center items-center h-screen py-5 px-3">
        <div>
          <img className="mb-9" src="./images/web-logo.png" alt="web-logo" />

          <div className="bg-sky border border-sky py-5 md:py-9 px-4 md:px-16 rounded-[20px] max-w-[614px]">
            <h3 className="text-secondary text-xl sm:text-2xl font-semibold mb-4">
              New password link sent
            </h3>
            <p className="text-base font-light text-secondary text-opacity-80 mb-4">
              If your email matches our records, we've sent you instructions to
              reset your password. Check your inbox.
            </p>
            <p className="text-base font-light text-secondary text-opacity-80">
              If you don't see it, check your spam folder or resend it.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSent;

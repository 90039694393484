import { create } from "apisauce";
import { toast } from "react-toastify";
import { BehaviorSubject } from "rxjs";
import { apiBaseUrl, apiBaseUrlUsers } from "../constants/environment.constants";
import { STORAGE_KEYS } from "../constants/keys";
import { removeUser } from "../features/Auth Slice/authSlice";
import { store } from "../store/store";
import {
  IAuthReqBody,
  ICenterRegistrationReqBody,
  ICustomSignUpReqBody,
  INewPassword,
  IProfileCompleteReqBody,
  IReqToResetPassword,
  ISocialAuthReqBody,
  ISocialSignUpReqBody,
} from "../types/auth.interface";
import { getMessage } from "../utils/labelsMessage";
import spinnerSvc from "../utils/spinner-service";
import { storeTokens, storeUser } from "../utils/storingInStorage";

const isAuthenticatedSub = new BehaviorSubject(false);
// const signedIn$ = new Subject();

export const authApi = create({
  baseURL: apiBaseUrl,
  headers: { Accept: "application/json" },
});

authApi.axiosInstance.interceptors.request.use(
  async (config) => {
    spinnerSvc.start();
    return config;
  },
  (err) => {
    spinnerSvc.stop();
    console.error(err);
    return Promise.reject(err);
  }
);

authApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },
  (err) => {
    spinnerSvc.stop();
    if (err?.response?.data?.error === "is_already_verified") return Promise.reject(err);
    const message = getMessage(err?.response?.data?.error);
    toast.error(message); /// had to check the status of the response and based on that set toast type
    return Promise.reject(err);
  }
);


export const usersApi = create({
  baseURL: apiBaseUrlUsers,
  headers: { Accept: "application/json" },
});

usersApi.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },
  (err) => console.error(err)
);

usersApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
  }
);



export const signIn = async (
  redirectUri: string,
  clientID: string,
  reqBody?: IAuthReqBody | ISocialAuthReqBody,
  rememberMe?: boolean,
  rfToken?: string
) => {
  try {
    const res: any = await authApi.post(
      `/authorize?client_id=${clientID}&redirect_uri=${redirectUri}`,
      reqBody,
      rfToken ? { headers: { Authorization: `Bearer ${rfToken}` } } : undefined
    );
    if (res?.ok) {
      storeTokens(res?.data, rememberMe);
      storeUser(res?.data?.access_token);
      return { data: res?.data, ok: true };
    } else {
      return {
        error: true,
        token: res?.originalError?.response?.data?.access_Token,
      };
    }
  } catch (err: any) {
    return { error: true, token: err?.response?.data?.access_Token };
    // toast(err?.response?.date?.error);
  }
};

export const signUp = async (
  redirectUri: string,
  clientID: string,
  reqBody: ICustomSignUpReqBody | ISocialSignUpReqBody,
  rememberMe?: boolean
) => {
  try {
    const res: any = await authApi.post(
      `/signup?client_id=${clientID}&redirect_uri=${redirectUri}`,
      reqBody,
      {
        headers: { Accept: "application/json" },
      }
    );

    if (res?.ok) {
      storeTokens(res?.data, rememberMe);
      storeUser(res?.data?.access_token);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const completeProfile = async (
  redirectUri: string,
  clientID: string,
  reqBody: IProfileCompleteReqBody,
) => {
  const accToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN)
  try {
    const res: any = await authApi.put(
      `/updateProfile?client_id=${clientID}&redirect_uri=${redirectUri}`,
      reqBody, {
      headers: { Authorization: `Bearer ${accToken}` },
    }
    );
    if (res?.ok) {
      storeUser(res?.data?.access_token);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const centerRegistration = async (
  reqBody: ICenterRegistrationReqBody,
) => {
  const accToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN)
  try {
    const res: any = await authApi.post(
      `/center`,
      reqBody, {
      headers: { Authorization: `Bearer ${accToken}` },
    }
    );
    if (res?.ok) {
      storeUser(res?.data?.access_token);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const centerUpdate = async (
  reqBody: ICenterRegistrationReqBody,
) => {
  const accToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN)
  try {
    const res: any = await authApi.put(
      `/center`,
      reqBody, {
      headers: { Authorization: `Bearer ${accToken}` },
    }
    );
    if (res?.ok) {
      storeUser(res?.data?.access_token);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const refreshToken = async () => {
  try {
    const rfToken = localStorage.getItem(STORAGE_KEYS.RF_TOKEN);
    const rememberMe = JSON.parse(localStorage.getItem(STORAGE_KEYS.REMEMBER_ME) as string);
    const res: any = await authApi.post(`/refresh`, undefined, {
      headers: { Authorization: `Bearer ${rfToken}` },
    });
    if (res?.ok) {
      storeTokens(res?.data, rememberMe);
      return res?.data?.access_token;
    }
    throw new Error("expired token");
  } catch (err: any) {
    const message = getMessage(err?.response?.data?.error);
    toast(message);
    console.log("error: ", err);
  }
};

export const getTokens = async (clientId?: string, code?: string) => {
  try {
    await authApi.post(
      `/token?${clientId && "client_id=" + clientId}${clientId && code ? "&" : ""
      }${code && "code=" + code}`
    );
  } catch (err: any) {
    console.log("error: ", err);
  }
};

//use authApi in forget password instead of "api" service
export const reqToResetPassword = async (params: IReqToResetPassword) => {
  try {
    const response = await authApi.post(`/sendResetLink?email=${params?.email}&client_id=${params?.clientID}&redirect_uri=${params?.redirectUri}`);
    if (response && response.ok) {
      return { mailSent: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const createNewPassword = async (params: INewPassword) => {
  try {
    const response = await authApi.post(`/resetPassword?token=${params?.token}`, { newPassword: params?.newPassword });
    if (response && response?.ok) {
      return { ok: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (err: any) {
    console.log("error: ", err);
  }
};

export const fetchLabels = async () => {
  try {
    const response = await authApi.get("/labels");
    if (response?.ok) {
      return response?.data;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

export const verifyEmail = async (token: string) => {
  try {
    const response = await authApi.get(`/confirmation?token=${token}`);
    if (response?.ok) {
      return { data: response?.data, ok: true }
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

export const signOut = () => {
  //   notificationSocket?.disconnectSocket();
  store.dispatch(removeUser());
};

export const postFiles = async (formData: FormData) => {
  const accToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN)
  try {
    const response = await authApi.post(`/upload`, formData, {
      headers: { Authorization: `Bearer ${accToken}` },
    });
    if (response && response?.ok) {
      return { data: response?.data, ok: true };
    } else {
      throw new Error("Couldn't upload file");
    }
  } catch (err: any) {
    console.log("error: ", err);
  }
}


export const fetchAccountInfo = async () => {
  try {
    const res: any = await usersApi.get(`/users/user`);
    if (res?.ok) {
      // localStorage.setItem(STORAGE_KEYS.ROLE, res?.data?.roles);
      return { data: res?.data, ok: true };
    } else return { error: true, ok: false };
  } catch (err) {
    console.log("error: ", err);
  }
};

export const isAuthenticated = () => {
  return isAuthenticatedSub.asObservable();
};

export const setIsAuthenticated = (isAuthenticated: boolean) => {
  isAuthenticatedSub.next(isAuthenticated);
};
